import React from 'react';
import Hero from '../components/Hero';
import FloatingCTA from '../components/FloatingCTA';
import Pricing from '../components/Pricing';
import Footer from '../components/Footer';

export default function Home() {
  return (
    <>
      <Hero />
      <FloatingCTA />
      <Pricing />
      <Footer />
    </>
  );
}