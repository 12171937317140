import React from 'react';
import { X } from 'lucide-react';

interface NetworkingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const networkingSteps = [
  {
    title: "1. Définir votre Objectif de Networking",
    content: "Pourquoi voulez-vous réseauter ?",
    bullets: [
      "Trouver des partenaires stratégiques : Collaborations, co-création de produits",
      "Augmenter votre visibilité : Accès à une nouvelle audience via des collaborations",
      "Accéder à des ressources exclusives : Expertise, conseils, financement, outils",
      "Renforcer votre crédibilité : Être associé à des experts ou des influenceurs reconnus"
    ]
  },
  {
    title: "2. Identifier les Personnes Clés",
    content: "Les types de relations à cultiver :",
    bullets: [
      "Influenceurs de votre niche : Ceux qui ont déjà une grande audience",
      "Experts complémentaires : Proposez des collaborations avec des experts dont les compétences complètent les vôtres",
      "Clients ambassadeurs : Vos clients satisfaits peuvent devenir vos meilleurs promoteurs",
      "Groupes et communautés ciblées : Rejoignez des communautés sur LinkedIn, Facebook ou Slack"
    ]
  },
  {
    title: "3. Adoptez une Approche Humaine et Authentique",
    content: "Comment approcher vos contacts ?",
    bullets: [
      "Étudiez leur travail : Avant d'entrer en contact, comprenez leurs projets et leurs besoins",
      "Personnalisez vos messages : Adressez-vous à eux de manière unique et spécifique",
      "Offrez avant de demander : Proposez de la valeur avant de demander une collaboration"
    ]
  },
  {
    title: "4. Exploitez les Événements et Communautés",
    content: "En ligne et en présentiel :",
    bullets: [
      "Participez à des webinaires, masterminds et conférences virtuelles",
      "Créez ou rejoignez des groupes sur LinkedIn, Facebook, ou Discord",
      "Organisez des lives sur Instagram ou YouTube",
      "Assistez à des conférences, meet-ups, ou séminaires locaux"
    ]
  },
  {
    title: "5. Créez un Cercle de Mastermind",
    content: "Comment démarrer :",
    bullets: [
      "Identifiez 3 à 6 personnes dans votre réseau ayant des compétences complémentaires",
      "Organisez des réunions régulières (en ligne ou en présentiel)",
      "Structurez chaque réunion avec un ordre du jour clair"
    ]
  },
  {
    title: "6. Collaborez pour Créer de la Valeur",
    content: "Exemples de collaborations :",
    bullets: [
      "Webinaires co-animés : Invitez un expert pour co-animer une session",
      "Produits ou services partagés : Créez ensemble une formation ou un programme",
      "Invitations croisées : Échangez des interventions dans vos contenus respectifs"
    ]
  },
  {
    title: "7. Exploitez les Réseaux Sociaux Stratégiquement",
    content: "Plateformes clés pour le networking d'infopreneurs :",
    bullets: [
      "LinkedIn : Connectez-vous avec des professionnels",
      "Instagram : Collaborez avec des influenceurs",
      "Twitter : Participez aux discussions avec des hashtags pertinents",
      "Clubhouse ou Twitter Spaces : Animez des discussions en direct"
    ]
  },
  {
    title: "8. Créez et Nourrissez Votre Propre Communauté",
    content: "Comment :",
    bullets: [
      "Groupe privé sur Facebook, Discord ou Telegram",
      "Sessions exclusives : Q&A en direct ou ateliers réservés",
      "Valorisez les interactions : Mentionnez et remerciez les membres actifs"
    ]
  },
  {
    title: "9. Investissez dans des Programmes d'Experts",
    content: "Exemples :",
    bullets: [
      "Masterclass payantes : Apprenez auprès des meilleurs",
      "Programmes de certification : Renforcez votre crédibilité"
    ]
  },
  {
    title: "10. Mesurez et Entretenez vos Relations",
    content: "Gérer efficacement votre réseau :",
    bullets: [
      "Base de contacts : Utilisez un outil comme Notion, Trello ou un CRM",
      "Donnez régulièrement des nouvelles : Partagez vos succès",
      "Célébrez leurs réussites : Envoyez des messages de félicitations"
    ]
  }
];

export default function NetworkingModal({ isOpen, onClose }: NetworkingModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white/90 backdrop-blur-md max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Networking d'Exception : Guide Complet</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-black/5 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="prose prose-lg max-w-none">
          <p className="lead">
            Créer un networking d'exception en tant qu'infopreneur est une stratégie puissante pour 
            développer votre activité, renforcer votre crédibilité et accéder à des opportunités uniques. 
            Voici un guide structuré pour bâtir un réseau exceptionnel et tirer profit de vos relations 
            professionnelles.
          </p>

          {networkingSteps.map((step, index) => (
            <div key={index} className="mb-8 pb-8 border-b last:border-0">
              <h3 className="text-xl font-bold mb-4">{step.title}</h3>
              <p className="mb-4">{step.content}</p>
              <ul className="list-disc pl-4">
                {step.bullets.map((bullet, i) => (
                  <li key={i} className="mb-2">{bullet}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}