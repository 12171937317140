import React, { useState } from 'react';
import { MapPin, X } from 'lucide-react';

interface LocationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LocationModal({ isOpen, onClose }: LocationModalProps) {
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white/90 backdrop-blur-md w-full max-w-5xl max-h-[90vh] rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 scale-100"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="p-6 bg-gradient-to-r from-[#fede58]/20 to-transparent">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <MapPin className="w-6 h-6 text-[#fede58]" />
              <div>
                <h3 className="text-2xl font-bold">Lexington, Kentucky</h3>
                <p className="text-gray-600 text-sm">GB Group Event Location</p>
              </div>
            </div>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-black/5 rounded-full transition-colors"
            >
              <X className="w-6 h-6 text-gray-500" />
            </button>
          </div>
        </div>

        {/* Map Container */}
        <div className="relative aspect-[16/9]">
          {/* Loading Overlay */}
          {!isMapLoaded && (
            <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
              <div className="w-12 h-12 border-4 border-[#fede58] border-t-transparent rounded-full animate-spin"></div>
            </div>
          )}
          
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50803.36598696367!2d-84.52301344179685!3d38.02832995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884244299ceb25%3A0x84f08341908c4fdd!2sLexington%2C%20KY%2C%20USA!5e0!3m2!1sen!2sfr!4v1710861445099!5m2!1sen!2sfr"
            className="w-full h-full border-0"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            onLoad={() => setIsMapLoaded(true)}
          ></iframe>

          {/* Target Animation */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
            {/* Outer ping animation */}
            <div className="w-24 h-24 rounded-full border-4 border-red-500/30 animate-[ping_2s_cubic-bezier(0,0,0.2,1)_infinite]"></div>
            {/* Middle ring */}
            <div className="absolute top-1/2 left-1/2 w-16 h-16 -translate-x-1/2 -translate-y-1/2 rounded-full border-4 border-red-500/50"></div>
            {/* Inner ring */}
            <div className="absolute top-1/2 left-1/2 w-8 h-8 -translate-x-1/2 -translate-y-1/2 rounded-full border-4 border-red-500 bg-red-500/20"></div>
          </div>
        </div>

        {/* Footer */}
        <div className="p-4 bg-gray-50">
          <p className="text-sm text-gray-600 text-center">
            Cliquez en dehors de la carte pour fermer
          </p>
        </div>
      </div>
    </div>
  );
}