import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: "Qui êtes-vous et que proposez-vous ?",
    answer: "Je suis Georgis, infopreneur spécialisé(e) dans les domaines : [développement personnel, marketing digital, coaching, etc…]. Je propose des produits et services numériques, tels que :\n• Formations en ligne,\n• E-books,\n• Webinaires,\n• Sessions de coaching individuel ou collectif,\n• Diverses outils et ressources."
  },
  {
    question: "Comment accéder aux formations ou produits numériques ?",
    answer: "Après avoir passé commande sur gbgroup.xyz, vous recevrez un email contenant un lien de téléchargement ou un accès à l'espace membre. Si vous ne recevez pas l'email, vérifiez votre dossier spam ou contactez-nous à contact@gbgroup.xyz."
  },
  {
    question: "Puis-je obtenir un remboursement si je ne suis pas satisfait(e) ?",
    answer: "Conformément à la législation, les produits numériques immédiatement accessibles (e-books, formations téléchargeables) ne sont pas remboursables une fois l'accès fourni. Pour les services comme le coaching, vous pouvez annuler votre réservation dans un délai de 72 heures avant le début de la session. Consultez nos Conditions Générales de Vente pour plus de détails."
  },
  {
    question: "Puis-je avoir un aperçu des formations avant de les acheter ?",
    answer: "Oui, nous proposons souvent des extraits ou des webinaires gratuits pour vous donner un aperçu du contenu. Inscrivez-vous à notre newsletter ou suivez-nous sur Twitter: @gbgroup pour être informé des prochains événements."
  },
  {
    question: "Quels modes de paiement acceptez-vous ?",
    answer: "Nous acceptons uniquement les paiements par Cryptos Money :\n• L'utilisation du Bitcoin dans le cadre d'une activité commerciale apporte plus de souplesse,\n• Apporte une meilleur gestion de votre capital,\n• Permet d'économiser des coûts et d'envoyer des paiements transfrontaliers sans tenir compte des taux de change et des écarts de devises.\nTous les paiements sont sécurisés via nos partenaires de paiement."
  },
  {
    question: "Les sessions de coaching sont-elles en ligne ou en présentiel ?",
    answer: "Toutes nos sessions de coaching sont proposées en ligne via des plateformes comme Zoom, Loom, ou Telegram. Vous recevrez un lien d'accès après confirmation de votre réservation."
  },
  {
    question: "Puis-je personnaliser une formation ou une session de coaching ?",
    answer: "Oui, les sessions de coaching sont adaptées à vos besoins spécifiques. Pour des formations sur mesure, contactez-nous à contact@gbgroup.xyz avec vos besoins détaillés, et nous vous proposerons une solution adaptée."
  },
  {
    question: "Vos contenus sont-ils adaptés à tous les niveaux ?",
    answer: "Nos formations et produits sont conçus pour différents niveaux :\n• Débutant : Aucun prérequis nécessaire.\n• Intermédiaire : Vous devez avoir une connaissance de base du sujet.\n• Avancé : Pour les experts souhaitant approfondir leurs compétences.\nChaque produit est clairement étiqueté pour indiquer le niveau requis."
  }
];

export default function FAQ() {
  const [openItem, setOpenItem] = useState<number | null>(null);

  const renderContactInfo = (text: string) => {
    return text.replace(
      /(contact@gbgroup\.xyz|@gbgroup)/g,
      match => `<span class="text-[#fede58]">${match}</span>`
    );
  };

  return (
    <section className="py-24 bg-[#1A1A1C60] backdrop-blur-sm">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-5xl font-bold text-center mb-4 text-white">FAQ</h2>
          <p className="text-xl text-gray-300 text-center mb-16">Foire Aux Questions</p>
          
          <div className="space-y-6">
            {faqs.map((faq, index) => (
              <div 
                key={index}
                className={`transform transition-all duration-300 ${
                  openItem === index ? 'scale-102' : 'scale-100'
                }`}
              >
                <div className="relative">
                  {/* Glowing border effect */}
                  <div className={`absolute inset-0 rounded-xl bg-gradient-to-r from-[#fede58]/20 via-[#fede58]/10 to-[#fede58]/20 blur ${
                    openItem === index ? 'opacity-100' : 'opacity-0'
                  } transition-opacity duration-300`}></div>
                  
                  <div className={`relative bg-black/40 backdrop-blur-md rounded-xl overflow-hidden border border-white/10 ${
                    openItem === index ? 'ring-1 ring-[#fede58]/50' : ''
                  }`}>
                    <button
                      className="w-full px-8 py-6 flex items-center justify-between hover:bg-white/5 transition-colors"
                      onClick={() => setOpenItem(openItem === index ? null : index)}
                    >
                      <span className="font-semibold text-left text-white text-lg">{faq.question}</span>
                      <div className={`ml-4 transform transition-transform duration-300 ${
                        openItem === index ? 'rotate-180' : ''
                      }`}>
                        {openItem === index ? (
                          <ChevronUp className="w-6 h-6 text-[#fede58]" />
                        ) : (
                          <ChevronDown className="w-6 h-6 text-gray-400" />
                        )}
                      </div>
                    </button>
                    
                    {openItem === index && (
                      <div className="px-8 py-6 bg-white/5 border-t border-white/10">
                        <p 
                          className="text-gray-300 whitespace-pre-line leading-relaxed"
                          dangerouslySetInnerHTML={{ __html: renderContactInfo(faq.answer) }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}