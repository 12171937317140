import React from 'react';
import { X } from 'lucide-react';

interface EmailVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
}

export default function EmailVerificationModal({ isOpen, onClose, email }: EmailVerificationModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white/95 backdrop-blur-md w-full max-w-md rounded-xl shadow-2xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Vérification de l'email</h2>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <div className="p-6 space-y-4">
          <div className="bg-blue-50 p-4 rounded-lg">
            <p className="text-blue-800">
              Un email de vérification a été envoyé à <strong>{email}</strong>
            </p>
          </div>

          <div className="space-y-4 text-gray-600">
            <p>Pour accéder à votre compte :</p>
            <ol className="list-decimal pl-5 space-y-2">
              <li>Vérifiez votre boîte de réception</li>
              <li>Cliquez sur le lien de vérification dans l'email</li>
              <li>Une fois vérifié, vous pourrez vous connecter</li>
            </ol>
          </div>

          <div className="mt-6">
            <p className="text-sm text-gray-500">
              Si vous ne trouvez pas l'email, vérifiez votre dossier spam.
            </p>
          </div>

          <button
            onClick={onClose}
            className="w-full bg-gradient-to-r from-[#fede58] to-yellow-400 text-black font-bold py-3 px-6 rounded-lg text-lg relative overflow-hidden transition-all duration-300 hover:scale-105 shadow-xl hover:shadow-2xl"
          >
            Compris
          </button>
        </div>
      </div>
    </div>
  );
}