import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import InfopreneurPage from './pages/InfopreneurPage';
import BlogPage from './pages/BlogPage';
import EditionPage from './pages/EditionPage';
import TemoignagePage from './pages/TemoignagePage';
import FaqPage from './pages/FaqPage';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen">
        <Toaster position="top-center" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/infopreneur/*" element={<InfopreneurPage />} />
          <Route path="/infopreneur/blog" element={<BlogPage />} />
          <Route path="/infopreneur/edition" element={<EditionPage />} />
          <Route path="/infopreneur/temoignage" element={<TemoignagePage />} />
          <Route path="/infopreneur/faq" element={<FaqPage />} />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;