import React, { useState } from 'react';
import { X, Search, Globe, Server, Shield, Clock, Wifi } from 'lucide-react';
import { MonitoringService } from '../services/monitoring';
import { toast } from 'react-hot-toast';

interface MonitoringModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function MonitoringModal({ isOpen, onClose }: MonitoringModalProps) {
  const [domain, setDomain] = useState('');
  const [results, setResults] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<'whois' | 'dns' | 'security' | 'performance'>('whois');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
      if (!domainRegex.test(domain)) {
        throw new Error('Domaine invalide');
      }

      const data = await MonitoringService.analyzeWebsite(domain);
      setResults(data);
      toast.success('Analyse terminée avec succès !');
    } catch (error: any) {
      console.error('Error:', error);
      toast.error(error.message || 'Une erreur est survenue lors de l\'analyse');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      {/* Backdrop with blur and gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-black/90 via-purple-900/90 to-black/90 backdrop-blur-md"></div>

      {/* Modal Content */}
      <div 
        className="relative w-full max-w-4xl bg-black/40 backdrop-blur-xl rounded-2xl shadow-2xl border border-white/10 overflow-hidden"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="p-6 border-b border-white/10">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="text-2xl font-bold text-white">Domain Checking</h2>
              <p className="text-gray-400 mt-1">Analysez la santé et la performance de votre domaine</p>
            </div>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-white/10 rounded-full transition-colors text-gray-400 hover:text-white"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        {/* Search Form */}
        <form onSubmit={handleSubmit} className="p-6 border-b border-white/10">
          <div className="flex gap-4">
            <div className="flex-1">
              <input
                type="text"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                placeholder="Entrez un nom de domaine (ex: example.com)"
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-[#fede58] focus:border-transparent transition-all"
                disabled={loading}
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="bg-[#fede58] text-black px-8 py-3 rounded-lg font-medium hover:bg-[#fede58]/90 transition-all disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 shadow-lg shadow-[#fede58]/20"
            >
              {loading ? (
                <>
                  <div className="w-5 h-5 border-2 border-black border-t-transparent rounded-full animate-spin"></div>
                  Analyse...
                </>
              ) : (
                <>
                  <Search className="w-5 h-5" />
                  Analyser
                </>
              )}
            </button>
          </div>
        </form>

        {/* Results */}
        {results && (
          <div className="p-6">
            {/* Tabs */}
            <div className="flex gap-4 mb-6 border-b border-white/10">
              <button
                onClick={() => setActiveTab('whois')}
                className={`pb-2 px-4 font-medium transition-colors flex items-center gap-2 ${
                  activeTab === 'whois' ? 'border-b-2 border-[#fede58] text-[#fede58]' : 'text-gray-400'
                }`}
              >
                <Globe className="w-5 h-5" />
                WHOIS
              </button>
              <button
                onClick={() => setActiveTab('dns')}
                className={`pb-2 px-4 font-medium transition-colors flex items-center gap-2 ${
                  activeTab === 'dns' ? 'border-b-2 border-[#fede58] text-[#fede58]' : 'text-gray-400'
                }`}
              >
                <Server className="w-5 h-5" />
                DNS
              </button>
              <button
                onClick={() => setActiveTab('security')}
                className={`pb-2 px-4 font-medium transition-colors flex items-center gap-2 ${
                  activeTab === 'security' ? 'border-b-2 border-[#fede58] text-[#fede58]' : 'text-gray-400'
                }`}
              >
                <Shield className="w-5 h-5" />
                Sécurité
              </button>
              <button
                onClick={() => setActiveTab('performance')}
                className={`pb-2 px-4 font-medium transition-colors flex items-center gap-2 ${
                  activeTab === 'performance' ? 'border-b-2 border-[#fede58] text-[#fede58]' : 'text-gray-400'
                }`}
              >
                <Clock className="w-5 h-5" />
                Performance
              </button>
            </div>

            {/* Tab Content */}
            <div className="bg-white/5 rounded-lg p-6 text-white">
              {activeTab === 'whois' && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold mb-4">Informations WHOIS</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white/5 p-4 rounded-lg">
                      <p className="text-sm text-gray-400">Registrar</p>
                      <p className="font-medium">{results.whois.registrar}</p>
                    </div>
                    <div className="bg-white/5 p-4 rounded-lg">
                      <p className="text-sm text-gray-400">Date de création</p>
                      <p className="font-medium">{results.whois.created}</p>
                    </div>
                    <div className="bg-white/5 p-4 rounded-lg">
                      <p className="text-sm text-gray-400">Date d'expiration</p>
                      <p className="font-medium">{results.whois.expires}</p>
                    </div>
                    <div className="bg-white/5 p-4 rounded-lg">
                      <p className="text-sm text-gray-400">Statut</p>
                      <p className="font-medium">{results.whois.status}</p>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'dns' && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold mb-4">Enregistrements DNS</h3>
                  <div className="space-y-6">
                    <div>
                      <h4 className="font-medium mb-2">Enregistrements A</h4>
                      <ul className="bg-white/5 rounded-lg p-4 space-y-2">
                        {results.dns.a.map((record: string, index: number) => (
                          <li key={index} className="text-sm">{record}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Enregistrements MX</h4>
                      <ul className="bg-white/5 rounded-lg p-4 space-y-2">
                        {results.dns.mx.map((record: string, index: number) => (
                          <li key={index} className="text-sm">{record}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Enregistrements NS</h4>
                      <ul className="bg-white/5 rounded-lg p-4 space-y-2">
                        {results.dns.ns.map((record: string, index: number) => (
                          <li key={index} className="text-sm">{record}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'security' && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold mb-4">Sécurité</h3>
                  <div className="space-y-6">
                    <div>
                      <h4 className="font-medium mb-2">SSL/TLS</h4>
                      <div className="bg-white/5 rounded-lg p-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <p className="text-sm text-gray-400">Validité</p>
                            <p className={`font-medium ${results.security.ssl.valid ? 'text-green-400' : 'text-red-400'}`}>
                              {results.security.ssl.valid ? 'Valide' : 'Non valide'}
                            </p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-400">Expiration</p>
                            <p className="font-medium">{results.security.ssl.expires}</p>
                          </div>
                          <div className="col-span-2">
                            <p className="text-sm text-gray-400">Émetteur</p>
                            <p className="font-medium">{results.security.ssl.issuer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeTab === 'performance' && (
                <div className="space-y-4">
                  <h3 className="text-lg font-semibold mb-4">Performance</h3>
                  <div className="grid grid-cols-3 gap-6">
                    <div className="bg-white/5 rounded-lg p-6 flex flex-col items-center">
                      <Clock className="w-8 h-8 text-[#fede58] mb-2" />
                      <p className="text-sm text-gray-400">Temps de réponse</p>
                      <p className="text-2xl font-bold">{results.performance.responseTime}</p>
                    </div>
                    <div className="bg-white/5 rounded-lg p-6 flex flex-col items-center">
                      <Wifi className="w-8 h-8 text-[#fede58] mb-2" />
                      <p className="text-sm text-gray-400">Uptime</p>
                      <p className="text-2xl font-bold">{results.performance.uptime}</p>
                    </div>
                    <div className="bg-white/5 rounded-lg p-6 flex flex-col items-center">
                      <Clock className="w-8 h-8 text-[#fede58] mb-2" />
                      <p className="text-sm text-gray-400">Dernière vérification</p>
                      <p className="text-lg font-bold">{new Date(results.performance.lastChecked).toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}