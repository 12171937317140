import React, { useState } from 'react';
import CGVModal from './CGVModal';
import LegalModal from './LegalModal';
import PrivacyModal from './PrivacyModal';

export default function Footer() {
  const [showCGV, setShowCGV] = useState(false);
  const [showLegal, setShowLegal] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  return (
    <footer className="bg-[#111827] text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="flex justify-center mb-12">
          <img 
            src="https://avatars.githubusercontent.com/u/100054028?v=4"
            alt="GB Group Logo"
            className="w-20 h-20 rounded-full shadow-lg"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-white text-lg font-bold mb-4">GB Group</h3>
            <p className="text-sm text-gray-300">
              Organisation d'événements et de formations pour entrepreneurs et infopreneurs.
            </p>
          </div>
          <div>
            <h3 className="text-white text-lg font-bold mb-4">Contact</h3>
            <p className="text-sm">✉️ <span className="text-sky-400">contact@gbgroup.xyz</span></p>
            <p className="text-sm">𝕏 <span className="text-sky-400">@gbgroup</span></p>
          </div>
          <div>
            <h3 className="text-white text-lg font-bold mb-4">Liens utiles</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <button
                  onClick={() => setShowCGV(true)}
                  className="text-gray-300 hover:text-white transition-colors focus:outline-none hover:underline"
                >
                  Conditions Générales de Vente
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowLegal(true)}
                  className="text-gray-300 hover:text-white transition-colors focus:outline-none hover:underline"
                >
                  Mentions Légales
                </button>
              </li>
              <li>
                <button
                  onClick={() => setShowPrivacy(true)}
                  className="text-gray-300 hover:text-white transition-colors focus:outline-none hover:underline"
                >
                  Politique de Confidentialité
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-8 pt-8 text-center text-sm text-gray-400">
          <p>&copy; {new Date().getFullYear()} GB Group. Tous droits réservés.</p>
        </div>
      </div>

      <CGVModal 
        isOpen={showCGV} 
        onClose={() => setShowCGV(false)} 
      />
      <LegalModal 
        isOpen={showLegal} 
        onClose={() => setShowLegal(false)} 
      />
      <PrivacyModal 
        isOpen={showPrivacy} 
        onClose={() => setShowPrivacy(false)} 
      />
    </footer>
  );
}