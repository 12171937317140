import React from 'react';
import { Quote } from 'lucide-react';

const testimonials = [
  {
    text: "Merci à vous pour cette organisation j'ai beaucoup aimé les cases studies spéciales pour l'event. Merci à tout le monde !",
  },
  {
    text: "Bravo vous avez assuré ! Les confs c'était du concret, impec. Les intervenants étaient pointus sur leurs sujets, impec. Le mélange des niveaux du public est intéressant, ça met en perspective et ça génère des interactions enrichissantes",
  },
  {
    text: "Pas très à l'aise pour networker (plutôt profil geek), j'ai quand même pu échanger avec des inconnus, ce qui m'a bien fait plaisir. Trop hâte du prochain événement...",
  },
  {
    text: "C'est beau ce que vous faites continuez ne changez rien restez les mêmes et bon courages pour vos projets 2024 :)",
  },
  {
    text: "Merci encore c'était super ! En tant que débutant j'ai appris plein de choses et j'ai adoré échanger avec des gens qui pensent comme moi. C'était mon premier Event et qu'est-ce que ça fait du bien ! Merci Infiniment à vous",
  },
  {
    text: "Un grand merci pour l'organisation même si je sais que je ne testerai pas tout ça permet de voir ce qu'il est possible de faire dans le make 'Infopreneur' en ligne et de construire de vrais business",
  }
];

export default function Testimonials() {
  return (
    <section className="py-24 bg-[#111827]" id="testimonials">
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-20 text-white">Ils témoignent</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className="testimonial-card bg-white/10 backdrop-blur-lg p-8 rounded-2xl relative hover:shadow-xl border border-white/10"
            >
              <Quote className="absolute top-4 left-4 w-8 h-8 text-[#fede58] opacity-50" />
              <p className="text-white/90 italic pt-8 relative z-10 leading-relaxed">{testimonial.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}