import React from 'react';
import { X } from 'lucide-react';

interface LegalModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LegalModal({ isOpen, onClose }: LegalModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4 modal-enter"
      onClick={onClose}
    >
      <div 
        className="bg-white/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl shadow-2xl"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 bg-white/95 backdrop-blur-md px-8 py-6 border-b border-gray-200 flex justify-between items-center z-10">
          <div>
            <h2 className="text-2xl font-bold bg-gradient-to-r from-[#fede58] to-yellow-500 bg-clip-text text-transparent">
              Mentions Légales
            </h2>
            <p className="text-sm text-gray-500 mt-1">Dernière mise à jour : 15/11/2024</p>
          </div>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Fermer"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content with improved typography and spacing */}
        <div className="px-8 py-6 space-y-8 modal-content">
          <section>
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Éditeur du Site</h3>
            <div className="bg-yellow-50/50 border-l-4 border-[#fede58] p-4 rounded-r-lg">
              <ul className="space-y-2 text-gray-700">
                <li><strong>Nom de l'éditeur :</strong> GB Group</li>
                <li><strong>Statut juridique :</strong> LLC.</li>
                <li><strong>SIRET ou EIN :</strong> [9*********]</li>
                <li><strong>Adresse :</strong> Lexington, KY</li>
                <li><strong>E-mail :</strong> <a href="mailto:contact@gbgroup.xyz" className="text-blue-600 hover:underline">contact@gbgroup.xyz</a></li>
                <li><strong>Téléphone :</strong> +1 859-687-4687</li>
                <li><strong>Directeur de la publication :</strong> Georgis B.</li>
              </ul>
            </div>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Hébergeur du Site</h3>
            <ul className="space-y-2 text-gray-700">
              <li><strong>Nom de l'hébergeur :</strong> Hostinger</li>
              <li><strong>Adresse de l'hébergeur :</strong> USA</li>
              <li><strong>Téléphone de l'hébergeur :</strong> [Voir Site Internet]</li>
            </ul>
          </section>

          <section className="bg-blue-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Activité</h3>
            <p className="text-gray-700 leading-relaxed">
              Le site gbgroup.xyz propose des produits numériques (formations en ligne, e-books, webinaires) 
              et services (coaching, consulting) dans le domaine du développement personnel et du marketing digital.
            </p>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Propriété Intellectuelle</h3>
            <p className="text-gray-700 leading-relaxed">
              Tous les contenus présents sur le site gbgroup.xyz (textes, images, vidéos, logos, etc.) sont la propriété 
              exclusive de gbgroup, sauf mention contraire explicite. Toute reproduction, distribution ou utilisation 
              sans autorisation est strictement interdite.
            </p>
          </section>

          <section className="bg-green-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Protection des Données Personnelles</h3>
            <div className="space-y-4">
              <p className="text-gray-700">
                Conformément au RGPD, les informations personnelles collectées sont utilisées uniquement dans 
                le cadre de la relation commerciale ou de la fourniture des services.
              </p>
              <div className="pl-4 border-l-4 border-green-200">
                <h4 className="font-semibold mb-2">Droits des utilisateurs :</h4>
                <ul className="list-disc pl-6 text-gray-700 space-y-2">
                  <li>Droit d'accès, de modification, de rectification et de suppression des données</li>
                  <li>Pour exercer ces droits, contactez-nous à : <a href="mailto:contact@gbgroup.xyz" className="text-blue-600 hover:underline">contact@gbgroup.xyz</a></li>
                </ul>
              </div>
            </div>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Responsabilité</h3>
            <p className="text-gray-700 leading-relaxed">
              Gbgroup s'efforce de fournir des informations exactes et à jour, mais ne peut garantir l'exactitude 
              ou l'exhaustivité des contenus. En cas de lien vers un site externe, gbgroup décline toute 
              responsabilité quant au contenu de ces sites tiers.
            </p>
          </section>

          <section className="bg-blue-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Cookies</h3>
            <p className="text-gray-700 leading-relaxed">
              Le site utilise des cookies pour améliorer l'expérience utilisateur et à des fins statistiques. 
              Vous pouvez paramétrer votre navigateur pour désactiver les cookies si vous le souhaitez.
            </p>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Crédits</h3>
            <ul className="space-y-2 text-gray-700">
              <li><strong>Conception et réalisation du site :</strong> gbgroup</li>
              <li><strong>Photos et illustrations :</strong> Créations personnelles</li>
            </ul>
          </section>

          <section className="bg-purple-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Droit Applicable</h3>
            <p className="text-gray-700 leading-relaxed">
              Les présentes mentions légales sont régies par le droit français. En cas de litige, les tribunaux 
              compétents seront ceux du lieu de résidence de l'éditeur ou du client.
            </p>
          </section>

          <section className="border-t pt-8">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Contact</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-gray-700">Pour toute question, vous pouvez nous contacter :</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
                <li>Par email : <a href="mailto:contact@gbgroup.xyz" className="text-blue-600 hover:underline">contact@gbgroup.xyz</a></li>
                <li>Par courrier : Lexington, KY</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}