import React from 'react';
import { X } from 'lucide-react';

interface ImpactModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const impactStrategies = [
  {
    title: "1. Offrir une Solution à un Problème Pressant",
    why: "Les gens cherchent des réponses immédiates à leurs problèmes.",
    how: [
      "Identifiez un problème spécifique dans votre niche",
      "Créez une solution rapide, comme une mini-formation, un webinaire gratuit, ou un guide téléchargeable",
      "Promouvez cette solution sur vos réseaux sociaux, mailing list ou publicités"
    ],
    impact: "Vous attirez immédiatement les personnes intéressées, créant une relation de confiance."
  },
  {
    title: "2. Proposer un Produit ou Service \"Prêt à l'Emploi\"",
    why: "Les clients préfèrent des solutions concrètes et immédiatement exploitables.",
    how: [
      "Créez des outils ou modèles clés en main",
      "Offrez un accès rapide après paiement via un espace membre ou un lien de téléchargement"
    ],
    impact: "Une satisfaction immédiate renforce votre image de professionnel efficace."
  },
  {
    title: "3. Créer un Contenu Viral ou Inspirant",
    why: "Un contenu engageant peut rapidement élargir votre audience.",
    how: [
      "Publiez une vidéo ou un post sur TikTok, Instagram Reels, ou YouTube Shorts",
      "Utilisez des titres accrocheurs et des appels à l'action clairs"
    ],
    impact: "Plus de visibilité, des partages, et une croissance rapide de votre communauté."
  },
  {
    title: "4. Offrir une Session de Coaching ou un Atelier Gratuit",
    why: "Les gens aiment tester avant d'acheter.",
    how: [
      "Proposez une session découverte gratuite de 15-30 minutes ou un webinaire live",
      "À la fin, offrez une offre spéciale pour convertir les participants"
    ],
    impact: "Créez de la valeur immédiate tout en générant des ventes."
  },
  {
    title: "5. Exploiter le Marketing d'Urgence",
    why: "Les offres limitées incitent à l'action rapide.",
    how: [
      "Lancez une offre promotionnelle avec une limite de temps",
      "Utilisez des outils comme des minuteries sur votre site"
    ],
    impact: "Augmentez vos ventes dans un court laps de temps."
  },
  {
    title: "6. Témoignages et Preuves Sociales",
    why: "Les gens font confiance aux expériences des autres.",
    how: [
      "Recueillez des témoignages de vos premiers clients",
      "Mettez en avant des résultats concrets obtenus"
    ],
    impact: "Renforcez immédiatement votre crédibilité."
  },
  {
    title: "7. Exploiter les Plateformes de Réseautage",
    why: "Les partenariats peuvent booster votre visibilité rapidement.",
    how: [
      "Collaborez avec d'autres infopreneurs ou influenceurs",
      "Proposez un échange de visibilité via des interviews"
    ],
    impact: "Atteignez une audience plus large en peu de temps."
  },
  {
    title: "8. Utiliser un Call-to-Action Puissant",
    why: "Sans appel à l'action, vos prospects ne savent pas quoi faire ensuite.",
    how: [
      "Ajoutez un CTA clair à vos contenus",
      "Optimisez vos boutons et liens pour qu'ils soient visibles"
    ],
    impact: "Augmentez immédiatement vos taux de conversion."
  },
  {
    title: "9. Email Marketing pour des Résultats Immédiats",
    why: "Une campagne bien ciblée peut générer des ventes en quelques heures.",
    how: [
      "Envoyez un email avec une offre spéciale",
      "Incluez des témoignages et un lien vers une action immédiate"
    ],
    impact: "Engagez votre audience existante et obtenez des résultats rapides."
  },
  {
    title: "10. Appliquer des Micro-Actions",
    why: "Les petites victoires encouragent les clients à continuer avec vous.",
    how: [
      "Donnez des conseils pratiques qui génèrent des résultats rapides",
      "Montrez aux clients comment passer à l'étape suivante"
    ],
    impact: "Les résultats rapides incitent à la fidélisation."
  }
];

export default function ImpactModal({ isOpen, onClose }: ImpactModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white/90 backdrop-blur-md max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Impact Immédiat : Stratégies et Actions Concrètes</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-black/5 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="prose prose-lg max-w-none">
          <p className="lead">
            L'impact immédiat est essentiel pour captiver votre audience, construire votre crédibilité, 
            et générer rapidement des résultats en tant qu'infopreneur. Voici des stratégies et des 
            actions concrètes pour avoir un impact immédiat :
          </p>

          {impactStrategies.map((strategy, index) => (
            <div key={index} className="mb-8 pb-8 border-b last:border-0">
              <h3 className="text-xl font-bold mb-4">{strategy.title}</h3>
              <div className="pl-4 space-y-4">
                <div>
                  <p className="font-semibold">Pourquoi :</p>
                  <p>{strategy.why}</p>
                </div>
                <div>
                  <p className="font-semibold">Comment :</p>
                  <ul>
                    {strategy.how.map((step, i) => (
                      <li key={i}>{step}</li>
                    ))}
                  </ul>
                </div>
                <div>
                  <p className="font-semibold">Impact :</p>
                  <p>{strategy.impact}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}