import React from 'react';
import { X } from 'lucide-react';

interface PrivacyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function PrivacyModal({ isOpen, onClose }: PrivacyModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4 modal-enter"
      onClick={onClose}
    >
      <div 
        className="bg-white/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl shadow-2xl"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 bg-white/95 backdrop-blur-md px-8 py-6 border-b border-gray-200 flex justify-between items-center z-10">
          <div>
            <h2 className="text-2xl font-bold bg-gradient-to-r from-[#fede58] to-yellow-500 bg-clip-text text-transparent">
              Politique de Confidentialité
            </h2>
            <p className="text-sm text-gray-500 mt-1">Dernière mise à jour : 15/11/2024</p>
          </div>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Fermer"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content with improved typography and spacing */}
        <div className="px-8 py-6 space-y-8 modal-content">
          <section>
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Introduction</h3>
            <div className="bg-yellow-50/50 border-l-4 border-[#fede58] p-4 rounded-r-lg">
              <p className="text-gray-700 leading-relaxed">
                La présente Politique de Confidentialité explique comment gbgroup collecte, utilise, et protège 
                les informations personnelles que vous fournissez en utilisant le site gbgroup.xyz ou en accédant 
                à nos services.
              </p>
            </div>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">1. Responsable du Traitement</h3>
            <ul className="space-y-2 text-gray-700">
              <li><strong>Nom du responsable :</strong> GB Group</li>
              <li><strong>Adresse :</strong> Lexington, KY</li>
              <li><strong>E-mail :</strong> <a href="mailto:contact@gbgroup.xyz" className="text-blue-600 hover:underline">contact@gbgroup.xyz</a></li>
            </ul>
          </section>

          <section className="bg-blue-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">2. Données Collectées</h3>
            <div className="space-y-4">
              <div className="pl-4 border-l-4 border-blue-200">
                <h4 className="font-semibold mb-2">Données d'identification :</h4>
                <p className="text-gray-700">Nom, prénom, adresse e-mail, numéro de téléphone, etc.</p>
              </div>
              <div className="pl-4 border-l-4 border-blue-200">
                <h4 className="font-semibold mb-2">Données de paiement :</h4>
                <p className="text-gray-700">Informations nécessaires pour le traitement des paiements (via plateforme sécurisée).</p>
              </div>
              <div className="pl-4 border-l-4 border-blue-200">
                <h4 className="font-semibold mb-2">Données de navigation :</h4>
                <p className="text-gray-700">Adresse IP, type de navigateur, pages visitées, etc.</p>
              </div>
              <div className="pl-4 border-l-4 border-blue-200">
                <h4 className="font-semibold mb-2">Données spécifiques aux services :</h4>
                <p className="text-gray-700">Informations fournies dans le cadre d'un coaching ou d'une formation.</p>
              </div>
            </div>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">3. Utilisation des Données</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Fournir les produits et services commandés</li>
              <li>Gérer vos inscriptions aux formations et webinaires</li>
              <li>Communiquer des informations sur nos offres</li>
              <li>Améliorer votre expérience utilisateur</li>
              <li>Respecter nos obligations légales</li>
            </ul>
          </section>

          <section className="bg-green-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">4. Partage des Données</h3>
            <p className="text-gray-700 mb-4">
              Vos données personnelles ne seront jamais vendues à des tiers. Cependant, elles peuvent être partagées avec :
            </p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Prestataires de services (hébergement, paiement, e-mailing)</li>
              <li>Obligations légales en cas d'exigence réglementaire</li>
            </ul>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">5. Conservation des Données</h3>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Durée nécessaire à la fourniture des services</li>
              <li>Conservation légale (factures pendant 10 ans)</li>
            </ul>
          </section>

          <section className="bg-purple-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">6. Droits de l'Utilisateur</h3>
            <div className="space-y-4">
              <p className="text-gray-700">Conformément au RGPD, vous disposez des droits suivants :</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>Droit d'accès à vos données</li>
                <li>Droit de rectification</li>
                <li>Droit à l'effacement</li>
                <li>Droit d'opposition</li>
                <li>Droit à la portabilité</li>
                <li>Droit de retrait du consentement</li>
              </ul>
            </div>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">7. Cookies</h3>
            <div className="space-y-4">
              <p className="text-gray-700">Types de cookies utilisés :</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>Cookies nécessaires au fonctionnement du site</li>
                <li>Cookies analytiques pour les statistiques</li>
                <li>Cookies tiers (YouTube, Google Analytics)</li>
              </ul>
            </div>
          </section>

          <section className="bg-blue-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">8. Sécurité</h3>
            <p className="text-gray-700 leading-relaxed">
              Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger vos données. 
              Cependant, aucun système n'est complètement sécurisé, restez vigilant avec vos mots de passe.
            </p>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">9. Modifications</h3>
            <p className="text-gray-700 leading-relaxed">
              Nous nous réservons le droit de mettre à jour cette politique pour refléter les changements 
              législatifs ou nos pratiques. La version mise à jour sera publiée sur cette page.
            </p>
          </section>

          <section className="border-t pt-8">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Contact</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-gray-700">Pour toute question relative à cette politique, contactez-nous à :</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
                <li>Email : <a href="mailto:contact@gbgroup.xyz" className="text-blue-600 hover:underline">contact@gbgroup.xyz</a></li>
                <li>Adresse : Lexington, KY</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}