import React, { useState } from 'react';
import { X } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { saveLeadToFirebase } from '../services/firebase';
import { useNavigate } from 'react-router-dom';
import type { LeadFormData } from '../types';

interface PasswordConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  formData: LeadFormData;
  onSuccess: () => void;
}

export default function PasswordConfirmModal({ isOpen, onClose, formData, onSuccess }: PasswordConfirmModalProps) {
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (confirmPassword !== formData.password) {
      toast.error('Les mots de passe ne correspondent pas');
      return;
    }

    setIsSubmitting(true);

    try {
      await saveLeadToFirebase(formData);
      
      toast((t) => (
        <div className="flex flex-col items-center">
          <p className="font-semibold mb-2">Email de vérification envoyé !</p>
          <p className="text-sm text-gray-600">Veuillez vérifier votre boîte de réception pour activer votre compte.</p>
          <button
            className="mt-2 px-4 py-2 bg-[#fede58] text-black rounded-lg font-medium hover:bg-[#fede58]/90"
            onClick={() => toast.dismiss(t.id)}
          >
            Compris
          </button>
        </div>
      ), {
        duration: 6000,
        position: 'top-center',
      });

      setConfirmPassword(''); // Reset confirmation password
      onSuccess(); // Reset main form and close modal
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        toast.error('Cet email est déjà utilisé. Veuillez vous connecter.');
      } else {
        toast.error(error.message || 'Une erreur est survenue. Veuillez réessayer.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white/95 backdrop-blur-md w-full max-w-md rounded-xl shadow-2xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Confirmer le mot de passe</h2>
            <button 
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Confirmez votre mot de passe
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#fede58] focus:border-transparent"
              required
              minLength={6}
              placeholder="••••••••"
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-gradient-to-r from-[#fede58] to-yellow-400 text-black font-bold py-3 px-6 rounded-lg text-lg relative overflow-hidden transition-all duration-300 hover:scale-105 shadow-xl hover:shadow-2xl disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Traitement...' : 'Confirmer'}
          </button>
        </form>
      </div>
    </div>
  );
}