import React from 'react';
import { useScroll } from '../hooks/useScroll';

export default function FloatingCTA() {
  const isVisible = useScroll(window.innerHeight);

  if (!isVisible) return null;

  const scrollToReservation = () => {
    document.getElementById('reservation')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <button 
        onClick={scrollToReservation}
        className="floating-cta bg-black text-white font-bold py-4 px-8 rounded-full shadow-2xl transform hover:scale-105 transition-all duration-300"
      >
        Commencer maintenant
      </button>
    </div>
  );
}