import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import InfoHeader from '../components/InfoHeader';
import { FORMATION_DESCRIPTION } from '../constants/content';
import MonitoringModal from '../components/MonitoringModal';

const getTitle = (path: string) => {
  if (path.includes('actualites')) return 'Actualités';
  if (path.includes('blog')) return 'Blog';
  if (path.includes('saas')) return 'SaaS';
  return 'Infopreneur';
};

export default function InfopreneurPage() {
  const location = useLocation();
  const title = getTitle(location.pathname);
  const [showMonitoring, setShowMonitoring] = useState(false);

  return (
    <div className="min-h-screen bg-[#111827] text-white">
      <InfoHeader onMonitoringClick={() => setShowMonitoring(true)} />
      
      {/* Video Background */}
      <div className="relative h-screen">
        <div className="absolute inset-0 bg-black/50 z-10"></div>
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 w-full h-full object-cover"
        >
          <source src="https://gbgroup.xyz/upvideo/info-4644.webm" type="video/webm" />
        </video>
        
        {/* Content */}
        <div className="relative z-20 container mx-auto px-4 h-full flex flex-col items-center justify-center text-center">
          <div className="max-w-4xl mx-auto">
            <h1 
              className="text-6xl font-bold mb-8 bg-gradient-to-r from-[#fede58] to-yellow-500 bg-clip-text text-transparent transition-all duration-500 animate-[fadeIn_0.5s_ease-out]"
              style={{ fontFamily: "'Montserrat', sans-serif" }}
            >
              {title}
            </h1>
            
            <p className="text-xl text-gray-300 max-w-3xl mx-auto animate-[fadeIn_0.5s_ease-out_0.2s] opacity-0 [animation-fill-mode:forwards]">
              {FORMATION_DESCRIPTION}
            </p>
          </div>
        </div>

        {/* MonitoringModal */}
        <MonitoringModal 
          isOpen={showMonitoring}
          onClose={() => setShowMonitoring(false)}
        />
      </div>
    </div>
  );
}