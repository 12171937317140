import { collection, addDoc, getDocs, query, where, orderBy } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { LeadFormData, ReservationFormData } from '../types';

// Sauvegarder un nouveau lead
export const saveLeadToFirebase = async (data: LeadFormData) => {
  try {
    // Créer l'utilisateur dans Firebase Auth
    const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
    
    // Mettre à jour le profil avec le prénom
    await updateProfile(userCredential.user, {
      displayName: data.prenom
    });

    // Envoyer l'email de vérification sans URL de redirection
    await sendEmailVerification(userCredential.user);

    // Ajouter le lead dans Firestore avec l'ID de l'utilisateur
    const docRef = await addDoc(collection(db, 'leads'), {
      prenom: data.prenom,
      email: data.email,
      userId: userCredential.user.uid,
      createdAt: new Date(),
      source: 'formulaire_landing',
      status: 'non_verifie',
      lastUpdated: new Date()
    });

    return docRef.id;
  } catch (error: any) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error('Cet email est déjà utilisé. Veuillez vous connecter.');
    } else if (error.code === 'auth/weak-password') {
      throw new Error('Le mot de passe doit contenir au moins 6 caractères.');
    } else if (error.code === 'auth/invalid-email') {
      throw new Error('L\'adresse email n\'est pas valide.');
    } else if (error.code === 'permission-denied') {
      throw new Error('Erreur d\'autorisation. Veuillez réessayer.');
    }
    
    throw error;
  }
};

// Connexion d'un utilisateur existant
export const signInUser = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    
    // Vérifier si l'email est vérifié
    if (!userCredential.user.emailVerified) {
      throw new Error('Veuillez vérifier votre email avant de vous connecter');
    }
    
    // Vérifier si l'utilisateur existe dans la collection leads
    const leadsQuery = query(
      collection(db, 'leads'),
      where('userId', '==', userCredential.user.uid)
    );
    
    const querySnapshot = await getDocs(leadsQuery);
    
    if (querySnapshot.empty) {
      // Si l'utilisateur n'existe pas dans leads, l'ajouter
      await addDoc(collection(db, 'leads'), {
        prenom: userCredential.user.displayName || '',
        email: userCredential.user.email || '',
        userId: userCredential.user.uid,
        createdAt: new Date(),
        source: 'connexion_directe',
        status: 'verifie',
        lastUpdated: new Date()
      });
    } else {
      // Mettre à jour le statut si nécessaire
      const leadDoc = querySnapshot.docs[0];
      if (leadDoc.data().status === 'non_verifie') {
        await leadDoc.ref.update({
          status: 'verifie',
          lastUpdated: new Date()
        });
      }
    }

    return userCredential.user;
  } catch (error: any) {
    if (error.code === 'auth/user-not-found') {
      throw new Error('Aucun compte ne correspond à cet email.');
    } else if (error.code === 'auth/wrong-password') {
      throw new Error('Mot de passe incorrect.');
    } else if (error.code === 'auth/too-many-requests') {
      throw new Error('Trop de tentatives. Veuillez réessayer plus tard.');
    }
    
    throw error;
  }
};

// Sauvegarder une nouvelle réservation
export const saveReservationToFirebase = async (data: ReservationFormData) => {
  try {
    if (!auth.currentUser) {
      throw new Error('Vous devez être connecté pour effectuer une réservation.');
    }

    if (!auth.currentUser.emailVerified) {
      throw new Error('Veuillez vérifier votre email avant d\'effectuer une réservation.');
    }

    const docRef = await addDoc(collection(db, 'reservations'), {
      ...data,
      userId: auth.currentUser.uid,
      createdAt: new Date(),
      status: 'en_attente',
      paiementStatus: 'non_payé',
      lastUpdated: new Date()
    });

    return docRef.id;
  } catch (error: any) {
    if (error.code === 'permission-denied') {
      throw new Error('Erreur d\'autorisation. Veuillez vous reconnecter.');
    }
    
    throw error;
  }
};