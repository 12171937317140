import React from 'react';
import { X } from 'lucide-react';

interface CGVModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CGVModal({ isOpen, onClose }: CGVModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4 modal-enter"
      onClick={onClose}
    >
      <div 
        className="bg-white/95 backdrop-blur-md w-full max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl shadow-2xl"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 bg-white/95 backdrop-blur-md px-8 py-6 border-b border-gray-200 flex justify-between items-center z-10">
          <div>
            <h2 className="text-2xl font-bold bg-gradient-to-r from-[#fede58] to-yellow-500 bg-clip-text text-transparent">
              Conditions Générales de Vente
            </h2>
            <p className="text-sm text-gray-500 mt-1">Dernière mise à jour : 15/11/2024</p>
          </div>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Fermer"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content with improved typography and spacing */}
        <div className="px-8 py-6 space-y-8 modal-content">
          <section>
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Préambule</h3>
            <div className="bg-yellow-50/50 border-l-4 border-[#fede58] p-4 rounded-r-lg">
              <p className="text-gray-700 leading-relaxed">
                Les présentes Conditions Générales de Vente (CGV) s'appliquent à toutes les transactions réalisées sur le site gbgroup.xyz. 
                Ces CGV régissent la vente des produits numériques et services proposés par gbgroup.
              </p>
            </div>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">1. Informations sur les Produits et Services</h3>
            <p className="text-gray-700 mb-4">gbgroup propose des produits numériques et des services personnalisés :</p>
            <ul className="list-disc pl-6 text-gray-700 space-y-2">
              <li>Formations en ligne</li>
              <li>E-books</li>
              <li>Webinaires ou séminaires en ligne</li>
              <li>Sessions de coaching individuel ou collectif</li>
              <li>Autres produits ou services décrits sur le site</li>
            </ul>
          </section>

          <section className="bg-blue-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">2. Tarifs</h3>
            <div className="space-y-4">
              <p className="text-gray-700">Les prix des produits et services sont indiqués en dollar et comprennent toutes les taxes applicables (TTC).</p>
              <p className="text-gray-700">À tout moment, il peut y avoir des réductions ou promotions disponibles sous certaines conditions.</p>
            </div>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">3. Commandes</h3>
            <p className="text-gray-700 mb-4">Pour passer une commande, le client doit :</p>
            <ol className="list-decimal pl-6 text-gray-700 space-y-2">
              <li>Ajouter les produits ou services souhaités au panier</li>
              <li>Remplir les informations demandées (nom, email, adresse, etc.)</li>
              <li>Valider le paiement via la plateforme sécurisée</li>
            </ol>
          </section>

          <section className="bg-green-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">4. Paiement</h3>
            <p className="text-gray-700">
              Le paiement s'effectue via une plateforme sécurisée. Les paiements sont intégralement dus au moment de la commande.
              En cas de non-paiement, gbgroup se réserve le droit d'annuler la commande.
            </p>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">5. Livraison</h3>
            <p className="text-gray-700">
              Les produits numériques sont accessibles immédiatement après confirmation du paiement, via un lien de téléchargement 
              ou un espace membre sécurisé. Les services sont fournis selon les modalités et les dates convenues avec le client.
            </p>
          </section>

          <section className="bg-yellow-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">6. Droit de Rétractation</h3>
            <div className="space-y-4">
              <p className="text-gray-700">
                Conformément à l'article L.221-28 du Code de la consommation, le client renonce expressément à son droit de rétractation 
                pour les produits numériques immédiatement téléchargeables.
              </p>
              <p className="text-gray-700">
                Pour les services (comme le coaching), le client peut annuler sa commande sous 14 jours, sauf si le service a déjà 
                commencé avec son accord.
              </p>
            </div>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">7. Responsabilités</h3>
            <p className="text-gray-700">
              gbgroup s'efforce de fournir des informations et contenus de qualité. L'utilisation des produits et services est sous 
              la responsabilité exclusive du client. gbgroup ne pourra être tenu responsable des résultats obtenus ou non obtenus 
              par le client suite à l'utilisation des produits ou services.
            </p>
          </section>

          <section className="bg-blue-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">8. Propriété Intellectuelle</h3>
            <p className="text-gray-700">
              Tous les contenus (textes, vidéos, formations, e-books) restent la propriété exclusive de gbgroup. Toute reproduction, 
              diffusion ou utilisation commerciale sans autorisation est strictement interdite.
            </p>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">9. Confidentialité et Données Personnelles</h3>
            <p className="text-gray-700">
              gbgroup s'engage à protéger les données personnelles du client conformément au RGPD. Les données collectées sont utilisées 
              exclusivement pour le traitement des commandes et la gestion de la relation client.
            </p>
          </section>

          <section className="bg-red-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">10. Force Majeure</h3>
            <p className="text-gray-700">
              Gbgroup ne pourra être tenu responsable d'un retard ou d'une inexécution due à des circonstances indépendantes de sa 
              volonté (catastrophe naturelle, problème technique, etc.).
            </p>
          </section>

          <section className="hover:bg-gray-50/50 p-4 rounded-lg transition-colors">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">11. Résolution des Litiges</h3>
            <p className="text-gray-700">
              En cas de litige, le client est invité à contacter gbgroup à l'adresse contact@gbgroup.xyz. Si aucun accord amiable 
              n'est trouvé, les tribunaux compétents seront ceux du lieu de résidence de localisation de gbgroup.
            </p>
          </section>

          <section className="bg-green-50/50 p-4 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">12. Acceptation des CGV</h3>
            <p className="text-gray-700">
              En passant commande, le client déclare avoir lu et accepté les présentes CGV sans réserve.
            </p>
          </section>

          <section className="border-t pt-8">
            <h3 className="text-xl font-semibold mb-4 text-gray-800">Contact</h3>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-gray-700">Pour toute question relative aux CGV, contactez-nous à :</p>
              <ul className="list-disc pl-6 text-gray-700 space-y-2 mt-2">
                <li>Email : <a href="mailto:contact@gbgroup.xyz" className="text-blue-600 hover:underline">contact@gbgroup.xyz</a></li>
                <li>Adresse : Lexington, KY</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}