import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const programData = [
  {
    id: 1,
    title: "Consultant en Création de Produits d'Infopreneur",
    description: "Aider les infopreneurs à concevoir, structurer et lancer leurs propres produits d'information",
    details: {
      description: "Aider les infopreneurs à concevoir, structurer et lancer leurs propres produits d'information (e-books, cours en ligne, formations, webinaires).",
      services: "Définition de l'offre, choix de plateforme, stratégie de contenu, marketing digital, automatisation des ventes.",
      target: "Créateurs de contenu, coachs, experts en développement personnel/professionnel qui souhaitent monétiser leur expertise."
    },
    image: "https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&q=80&w=400"
  },
  {
    id: 2,
    title: "Stratégie de Marque Personnelle pour Experts",
    description: "Développer une marque personnelle forte et crédible",
    details: {
      description: "Accompagner les experts, coachs et consultants à développer leur marque personnelle pour accroître leur visibilité et leur crédibilité en ligne.",
      services: "Analyse et positionnement de la marque, gestion de l'image en ligne, création de contenu stratégique, optimisation des réseaux sociaux.",
      target: "Consultants, coachs, formateurs en ligne, et influenceurs du secteur éducatif."
    },
    image: "https://images.unsplash.com/photo-1557804506-669a67965ba0?auto=format&fit=crop&q=80&w=400"
  },
  {
    id: 3,
    title: "Consultant en Productivité et Automatisation",
    description: "Optimiser la productivité et automatiser les processus",
    details: {
      description: "Aider les infopreneurs à automatiser leurs processus et optimiser leur productivité, leur permettant ainsi de gérer efficacement leur activité tout en gagnant du temps.",
      services: "Intégration d'outils de gestion de tâches, automatisation des emails, suivi des leads, gestion des ventes et automatisation de contenu.",
      target: "Entrepreneurs numériques, infopreneurs déjà actifs qui souhaitent optimiser leurs opérations."
    },
    image: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&q=80&w=400"
  },
  {
    id: 4,
    title: "Création et Vente de Modules de Formation",
    description: "Développer des formations prêtes à l'emploi",
    details: {
      description: "Fournir des modules de formation prêts-à-vendre, personnalisables pour des thématiques spécifiques, comme le développement personnel, le marketing, ou les compétences techniques.",
      services: "Création de modules, accompagnement à la personnalisation, stratégie de vente, assistance à la mise en place sur les plateformes de cours en ligne.",
      target: "Consultants en développement personnel, coachs en entreprise, et entrepreneurs en ligne."
    },
    image: "https://images.unsplash.com/photo-1524178232363-1fb2b075b655?auto=format&fit=crop&q=80&w=400"
  },
  {
    id: 5,
    title: "Coaching en Lancement de Podcasts et Webinaires",
    description: "Créer et monétiser des contenus audio et vidéo",
    details: {
      description: "Accompagner les entrepreneurs à créer, lancer et promouvoir leur podcast ou leurs webinaires en tant qu'outils de communication et de monétisation.",
      services: "Choix du thème, structuration des épisodes, équipements et outils, stratégies de diffusion et promotion, création d'une audience.",
      target: "Infopreneurs, coachs, consultants souhaitant se positionner en experts dans leur domaine."
    },
    image: "https://images.unsplash.com/photo-1598550476439-6847785fcea6?auto=format&fit=crop&q=80&w=400"
  },
  {
    id: 6,
    title: "Consulting en Marketing de Contenu et SEO",
    description: "Optimiser la visibilité et le trafic organique",
    details: {
      description: "Aider les infopreneurs à améliorer leur visibilité et leur acquisition de trafic organique grâce au SEO et au marketing de contenu.",
      services: "Recherche de mots-clés, optimisation SEO, stratégie de blogging, création de contenu optimisé, suivi des performances.",
      target: "Créateurs de contenu en ligne, experts souhaitant augmenter le trafic sur leur site ou leurs formations en ligne."
    },
    image: "https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?auto=format&fit=crop&q=80&w=400"
  },
  {
    id: 7,
    title: "Coaching en Confiance et Prise de Parole",
    description: "Développer son aisance en communication",
    details: {
      description: "Proposer des sessions de coaching pour améliorer les compétences en communication et développer la confiance en soi, essentielle pour les webinaires, les vidéos en ligne et les cours.",
      services: "Coaching en prise de parole, gestion du trac, techniques de communication, storytelling, création de présence en ligne.",
      target: "Infopreneurs, formateurs et coachs en développement personnel."
    },
    image: "https://images.unsplash.com/photo-1475721027785-f74eccf877e2?auto=format&fit=crop&q=80&w=400"
  },
  {
    id: 8,
    title: "Stratégie de Diversification des Revenus",
    description: "Multiplier les sources de revenus",
    details: {
      description: "Accompagner les infopreneurs à diversifier leurs sources de revenus, en intégrant des options comme les abonnements, les memberships, les ventes d'e-books, et les collaborations.",
      services: "Évaluation de l'offre actuelle, proposition de modèles de revenus alternatifs, mise en place de stratégies de vente, création d'outils pour la fidélisation des clients.",
      target: "Experts en ligne cherchant à développer des revenus récurrents et une base de clients fidèles."
    },
    image: "https://images.unsplash.com/photo-1553729459-efe14ef6055d?auto=format&fit=crop&q=80&w=400"
  },
  {
    id: 9,
    title: "Stratégie de Monétisation des Réseaux Sociaux",
    description: "Générer des revenus via les médias sociaux",
    details: {
      description: "Enseigner aux infopreneurs comment tirer parti des réseaux sociaux pour générer des ventes et monétiser leur audience.",
      services: "Analyse de l'audience, stratégie de contenu, vente de produits ou services directement depuis les réseaux sociaux, utilisation de publicités payantes.",
      target: "Infopreneurs souhaitant élargir leur portée et monétiser leur audience sur les plateformes comme Instagram, TikTok, ou LinkedIn."
    },
    image: "https://images.unsplash.com/photo-1611162617474-5b21e879e113?auto=format&fit=crop&q=80&w=400"
  }
];

export default function Schedule() {
  const [expandedItem, setExpandedItem] = useState<number | null>(null);
  const [visibleItems, setVisibleItems] = useState<number[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = parseInt(entry.target.getAttribute('data-id') || '0');
            setVisibleItems((prev) => [...new Set([...prev, id])]);
          }
        });
      },
      { threshold: 0.2 }
    );

    document.querySelectorAll('.program-item').forEach((item) => {
      observer.observe(item);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <section className="py-24 bg-[#111827]" id="programme">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-16 text-white">Le Programme</h2>
        
        <div className="relative max-w-4xl mx-auto">
          {/* Ligne verticale centrale avec animation */}
          <div className="absolute left-1/2 top-0 bottom-0 w-0.5 bg-gradient-to-b from-[#fede58] to-[#fede58]/20 transform -translate-x-1/2">
            <div className="absolute top-0 left-1/2 w-4 h-4 bg-[#fede58] rounded-full transform -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute bottom-0 left-1/2 w-4 h-4 bg-[#fede58] rounded-full transform -translate-x-1/2 translate-y-1/2"></div>
          </div>
          
          {programData.map((item, index) => (
            <div
              key={item.id}
              data-id={item.id}
              className={`program-item relative mb-16 opacity-0 transform translate-y-8 transition-all duration-1000 ${
                visibleItems.includes(item.id) ? 'opacity-100 translate-y-0' : ''
              }`}
            >
              <div className={`flex items-start gap-8 ${index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'}`}>
                {/* Point sur la timeline avec animation */}
                <div className="absolute left-1/2 top-8 w-4 h-4 bg-[#fede58] rounded-full transform -translate-x-1/2 z-10">
                  <div className="absolute w-8 h-8 bg-[#fede58] rounded-full opacity-25 transform -translate-x-1/2 -translate-y-1/2 animate-ping"></div>
                  {/* Branche horizontale */}
                  <div className={`absolute top-1/2 ${index % 2 === 0 ? 'right-full' : 'left-full'} w-8 h-0.5 bg-[#fede58]`}></div>
                </div>
                
                {/* Contenu */}
                <div className="w-1/2">
                  <div 
                    className={`bg-white/10 backdrop-blur-sm rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl ${
                      expandedItem === item.id ? 'ring-2 ring-[#fede58]' : ''
                    }`}
                  >
                    <button
                      className="w-full text-left p-6"
                      onClick={() => setExpandedItem(expandedItem === item.id ? null : item.id)}
                    >
                      <div className="flex justify-between items-center">
                        <h3 className="text-xl font-bold text-white">{item.title}</h3>
                        <ChevronDown className={`transform transition-transform duration-300 text-white ${
                          expandedItem === item.id ? 'rotate-180' : ''
                        }`} />
                      </div>
                      <p className="text-gray-300 mt-2">{item.description}</p>
                    </button>
                    
                    {expandedItem === item.id && (
                      <div className="px-6 pb-6 animate-[fadeIn_0.3s_ease-out]">
                        <img
                          src={item.image}
                          alt={item.title}
                          className="w-full h-48 object-cover rounded-lg mb-4 hover:opacity-90 transition-opacity"
                        />
                        <div className="space-y-4 text-gray-300">
                          <p>{item.details.description}</p>
                          <div>
                            <h4 className="font-semibold mb-2 text-white">Services :</h4>
                            <p>{item.details.services}</p>
                          </div>
                          <div>
                            <h4 className="font-semibold mb-2 text-white">Public cible :</h4>
                            <p>{item.details.target}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}