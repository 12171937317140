import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  updateProfile,
  signOut as firebaseSignOut,
  sendEmailVerification,
  User
} from 'firebase/auth';
import { auth } from '../lib/firebase';
import { AuthFormData } from '../types';

// Inscription
export const signUp = async ({ email, password, nom }: AuthFormData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    
    // Mettre à jour le profil avec le nom
    if (nom) {
      await updateProfile(userCredential.user, { displayName: nom });
    }

    // Envoyer l'email de vérification sans URL de redirection
    await sendEmailVerification(userCredential.user);

    return userCredential.user;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Connexion
export const signIn = async ({ email, password }: AuthFormData) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    
    // Vérifier si l'email est vérifié
    if (!userCredential.user.emailVerified) {
      throw new Error('Veuillez vérifier votre email avant de vous connecter');
    }

    return userCredential.user;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Déconnexion
export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
  } catch (error: any) {
    throw new Error(error.message);
  }
};

// Renvoyer l'email de vérification
export const resendVerificationEmail = async (user: User) => {
  try {
    await sendEmailVerification(user);
  } catch (error: any) {
    throw new Error(error.message);
  }
};