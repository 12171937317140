import React from 'react';

const BitcoinLogo = () => (
  <div className="transform hover:scale-110 transition-transform duration-300">
    <svg viewBox="0 0 32 32" className="w-full h-full animate-float">
      <defs>
        <linearGradient id="bitcoinGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#F7931A">
            <animate attributeName="stopColor" values="#F7931A;#FFAE34;#F7931A" dur="2s" repeatCount="indefinite" />
          </stop>
          <stop offset="100%" stopColor="#FFAE34">
            <animate attributeName="stopColor" values="#FFAE34;#F7931A;#FFAE34" dur="2s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
        <filter id="glow">
          <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
          <feMerge>
            <feMergeNode in="coloredBlur"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <circle cx="16" cy="16" r="16" fill="url(#bitcoinGradient)">
        <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
      </circle>
      <path
        d="M22.5 14.5c.4-2.7-1.7-4.1-4.5-5.1l.9-3.7-2.2-.6-.9 3.6c-.6-.1-1.2-.3-1.8-.4l.9-3.6L12.7 4l-.9 3.7c-.5-.1-1-.2-1.4-.3l-3.1-.8-.6 2.4s1.7.4 1.6.4c.9.2 1.1.8 1 1.3l-1 4.3c.1 0 .2 0 .3.1-.1 0-.2-.1-.3-.1l-1.5 5.9c-.1.3-.4.7-1.1.5 0 .1-1.6-.4-1.6-.4L4 24.3l2.9.7c.5.1 1.1.3 1.6.4l-.9 3.7 2.2.6.9-3.7c.6.2 1.2.3 1.8.4l-.9 3.7 2.2.6.9-3.7c3.8.7 6.6.4 7.8-3 1-2.7-.1-4.3-2-5.3 1.4-.3 2.5-1.3 2.8-3.3zm-5 7.2c-.7 2.7-5.4 1.2-6.9.9l1.2-5c1.5.4 6.4 1.2 5.7 4.1zm.7-7.1c-.6 2.5-4.5 1.2-5.8.9l1.1-4.5c1.3.3 5.4 1 4.7 3.6z"
        fill="white"
        filter="url(#glow)"
        className="animate-pulse"
      />
    </svg>
  </div>
);

const EthereumLogo = () => (
  <div className="transform hover:scale-110 transition-transform duration-300">
    <svg viewBox="0 0 32 32" className="w-full h-full animate-float" style={{ animationDelay: '0.2s' }}>
      <defs>
        <linearGradient id="ethGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#627EEA">
            <animate attributeName="stopColor" values="#627EEA;#8799F0;#627EEA" dur="2s" repeatCount="indefinite" />
          </stop>
          <stop offset="100%" stopColor="#8799F0">
            <animate attributeName="stopColor" values="#8799F0;#627EEA;#8799F0" dur="2s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
      </defs>
      <circle cx="16" cy="16" r="16" fill="url(#ethGradient)">
        <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
      </circle>
      <g fill="#FFF" fillRule="nonzero">
        <path fillOpacity=".602" d="M16.498 4v8.87l7.497 3.35z">
          <animate attributeName="fillOpacity" values=".602;.8;.602" dur="2s" repeatCount="indefinite" />
        </path>
        <path d="M16.498 4L9 16.22l7.498-3.35z">
          <animate attributeName="fillOpacity" values="1;.8;1" dur="2s" repeatCount="indefinite" />
        </path>
        <path fillOpacity=".602" d="M16.498 21.968v6.027L24 17.616z">
          <animate attributeName="fillOpacity" values=".602;.8;.602" dur="2s" repeatCount="indefinite" />
        </path>
        <path d="M16.498 27.995v-6.028L9 17.616z">
          <animate attributeName="fillOpacity" values="1;.8;1" dur="2s" repeatCount="indefinite" />
        </path>
      </g>
    </svg>
  </div>
);

const SolanaLogo = () => (
  <div className="transform hover:scale-110 transition-transform duration-300">
    <svg viewBox="0 0 32 32" className="w-full h-full animate-float" style={{ animationDelay: '0.4s' }}>
      <defs>
        <linearGradient id="solanaGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#9945FF">
            <animate attributeName="stopColor" values="#9945FF;#14F195;#9945FF" dur="2s" repeatCount="indefinite" />
          </stop>
          <stop offset="100%" stopColor="#14F195">
            <animate attributeName="stopColor" values="#14F195;#9945FF;#14F195" dur="2s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
        <filter id="solanaGlow">
          <feGaussianBlur stdDeviation="1" result="blur" />
          <feFlood floodColor="#14F195" floodOpacity="0.5" result="glowColor" />
          <feComposite in="glowColor" in2="blur" operator="in" result="softGlow" />
          <feMerge>
            <feMergeNode in="softGlow" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle cx="16" cy="16" r="16" fill="black">
        <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
      </circle>
      <g transform="translate(8, 8)" filter="url(#solanaGlow)">
        <path
          d="M3.2 8.2L7 4.4c.2-.2.5-.4.8-.4h8.4c.3 0 .5.2.5.4 0 .1 0 .2-.1.3l-3.8 3.8c-.2.2-.5.4-.8.4H3.6c-.3 0-.5-.2-.5-.4 0-.1 0-.2.1-.3zm0 6l3.8-3.8c.2-.2.5-.4.8-.4h8.4c.3 0 .5.2.5.4 0 .1 0 .2-.1.3l-3.8 3.8c-.2.2-.5.4-.8.4H3.6c-.3 0-.5-.2-.5-.4 0-.1 0-.2.1-.3zm12.2-9.8L11.6.6c-.2-.2-.5-.4-.8-.4H2.4c-.3 0-.5.2-.5.4 0 .1 0 .2.1.3l3.8 3.8c.2.2.5.4.8.4h8.4c.3 0 .5-.2.5-.4 0-.1 0-.2-.1-.3z"
          fill="url(#solanaGradient)"
        >
          <animate
            attributeName="opacity"
            values="0.8;1;0.8"
            dur="2s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  </div>
);

const USDTLogo = () => (
  <div className="transform hover:scale-110 transition-transform duration-300">
    <svg viewBox="0 0 32 32" className="w-full h-full animate-float" style={{ animationDelay: '0.6s' }}>
      <defs>
        <linearGradient id="usdtGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#50AF95">
            <animate attributeName="stopColor" values="#50AF95;#2B8570;#50AF95" dur="2s" repeatCount="indefinite" />
          </stop>
          <stop offset="100%" stopColor="#2B8570">
            <animate attributeName="stopColor" values="#2B8570;#50AF95;#2B8570" dur="2s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
      </defs>
      <circle cx="16" cy="16" r="16" fill="url(#usdtGradient)">
        <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
      </circle>
      <path
        d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117"
        fill="white"
        filter="url(#glow)"
      >
        <animate attributeName="opacity" values="0.8;1;0.8" dur="1.5s" repeatCount="indefinite" />
      </path>
    </svg>
  </div>
);

const KaspaLogo = () => (
  <div className="transform hover:scale-110 transition-transform duration-300">
    <svg viewBox="0 0 32 32" className="w-full h-full animate-float" style={{ animationDelay: '0.8s' }}>
      <defs>
        <linearGradient id="kaspaGradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#7F52FF">
            <animate attributeName="stopColor" values="#7F52FF;#4A90E2;#7F52FF" dur="2s" repeatCount="indefinite" />
          </stop>
          <stop offset="100%" stopColor="#4A90E2">
            <animate attributeName="stopColor" values="#4A90E2;#7F52FF;#4A90E2" dur="2s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
      </defs>
      <circle cx="16" cy="16" r="16" fill="url(#kaspaGradient)">
        <animate attributeName="opacity" values="0.8;1;0.8" dur="2s" repeatCount="indefinite" />
      </circle>
      <path
        d="M21 8l-5 8-3-4h-3l4.5 6L9 26h3l5-8 5 8h3l-6-8 6-10z"
        fill="white"
        filter="url(#glow)"
      >
        <animate attributeName="opacity" values="0.8;1;0.8" dur="1.5s" repeatCount="indefinite" />
      </path>
    </svg>
  </div>
);

export default function CryptoLogos() {
  return (
    <div className="relative w-full max-w-xl mx-auto mb-2">
      {/* Background effects */}
      <div className="absolute inset-0 bg-gradient-to-r from-purple-900/20 via-blue-900/20 to-purple-900/20 rounded-2xl backdrop-blur-xl">
        <div className="absolute inset-0 bg-black/10 animate-pulse"></div>
      </div>
      
      {/* Chain connection lines */}
      <div className="absolute inset-0">
        <svg className="w-full h-full" viewBox="0 0 384 48">
          <defs>
            <linearGradient id="chainGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#F7931A">
                <animate
                  attributeName="stop-color"
                  values="#F7931A; #627EEA; #9945FF; #50AF95; #7F52FF; #F7931A"
                  dur="3s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="100%" stopColor="#627EEA">
                <animate
                  attributeName="stop-color"
                  values="#627EEA; #9945FF; #50AF95; #7F52FF; #F7931A; #627EEA"
                  dur="3s"
                  repeatCount="indefinite"
                />
              </stop>
            </linearGradient>
          </defs>
          <path
            d="M32 24 L352 24"
            className="chain-line"
            stroke="url(#chainGradient)"
            strokeWidth="2"
            filter="url(#glow)"
          >
            <animate
              attributeName="stroke-dashoffset"
              values="0;100"
              dur="3s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>

      {/* Crypto logos */}
      <div className="relative flex items-center justify-center gap-2 px-2 py-1">
        {[BitcoinLogo, EthereumLogo, SolanaLogo, USDTLogo, KaspaLogo].map((Logo, index) => (
          <div
            key={index}
            className="relative w-10 h-10"
          >
            <div className="absolute inset-0 bg-white/10 rounded-full blur-xl animate-pulse"></div>
            <div className="relative w-full h-full">
              <Logo />
            </div>
          </div>
        ))}
      </div>

      {/* Sparkles */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {[...Array(8)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-white rounded-full"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animation: `sparkle ${1 + Math.random() * 2}s ease-in-out infinite`,
              animationDelay: `${Math.random() * 2}s`
            }}
          />
        ))}
      </div>
    </div>
  );
}