import React, { useState } from 'react';
import { Lightbulb, Target, Users, BookOpen } from 'lucide-react';
import ThemesModal from './ThemesModal';
import ImpactModal from './ImpactModal';
import NetworkingModal from './NetworkingModal';
import ActiveLearningModal from './ActiveLearningModal';

const reasons = [
  {
    icon: <Lightbulb className="w-12 h-12" />,
    title: "Thématiques Innovantes",
    description: "Des sujets qui sortent de l'ordinaire, pour une vision unique et des insights puissants."
  },
  {
    icon: <Target className="w-12 h-12" />,
    title: "Impact Immédiat",
    description: "Des experts reconnus partagent des stratégies concrètes, prêtes à être appliquées."
  },
  {
    icon: <Users className="w-12 h-12" />,
    title: "Networking d'Exception",
    description: "Échangez avec des entrepreneurs accomplis et créez des connexions clés pour votre business."
  },
  {
    icon: <BookOpen className="w-12 h-12" />,
    title: "Apprentissage Actif",
    description: "Des études de cas et des démonstrations pratiques pour une mise en pratique immédiate."
  }
];

export default function WhyParticipate() {
  const [showThemes, setShowThemes] = useState(false);
  const [showImpact, setShowImpact] = useState(false);
  const [showNetworking, setShowNetworking] = useState(false);
  const [showActiveLearning, setShowActiveLearning] = useState(false);

  return (
    <section className="py-24 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-20">Pourquoi participer ?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {reasons.map((reason, index) => (
            <div 
              key={index}
              className="why-participate-card flex flex-col items-center text-center p-8 rounded-2xl bg-white border border-gray-100"
            >
              <div className="text-[#fede58] mb-6 bg-yellow-50 p-4 rounded-xl">
                {reason.icon}
              </div>
              <h3 
                className={`text-2xl font-bold mb-4 ${
                  index === 0 || index === 1 || index === 2 || index === 3 ? 'cursor-pointer hover:text-[#fede58] transition-colors' : ''
                }`}
                onClick={() => {
                  if (index === 0) setShowThemes(true);
                  if (index === 1) setShowImpact(true);
                  if (index === 2) setShowNetworking(true);
                  if (index === 3) setShowActiveLearning(true);
                }}
              >
                {reason.title}
              </h3>
              <p className="text-gray-600 leading-relaxed">{reason.description}</p>
            </div>
          ))}
        </div>
      </div>

      <ThemesModal 
        isOpen={showThemes}
        onClose={() => setShowThemes(false)}
      />
      <ImpactModal 
        isOpen={showImpact}
        onClose={() => setShowImpact(false)}
      />
      <NetworkingModal 
        isOpen={showNetworking}
        onClose={() => setShowNetworking(false)}
      />
      <ActiveLearningModal 
        isOpen={showActiveLearning}
        onClose={() => setShowActiveLearning(false)}
      />
    </section>
  );
}