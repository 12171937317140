import React from 'react';
import { X } from 'lucide-react';

interface ActiveLearningModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ActiveLearningModal({ isOpen, onClose }: ActiveLearningModalProps) {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="bg-white/90 backdrop-blur-md max-w-4xl max-h-[90vh] overflow-y-auto rounded-xl p-8 shadow-xl"
        onClick={e => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Apprentissage Actif pour Infopreneurs</h2>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-black/5 rounded-full transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="prose prose-lg max-w-none">
          <p className="lead">
            L'apprentissage actif est une méthode puissante pour un infopreneur. Elle permet d'acquérir des compétences 
            rapidement et de les appliquer immédiatement pour produire un impact tangible. Voici un guide sur 
            l'apprentissage actif spécifiquement adapté à un infopreneur :
          </p>

          <h3>Qu'est-ce que l'Apprentissage Actif pour un Infopreneur ?</h3>
          <p>L'apprentissage actif consiste à :</p>
          <ol>
            <li>Acquérir des connaissances ou des compétences de manière ciblée.</li>
            <li>Appliquer ces apprentissages immédiatement dans vos projets ou services.</li>
            <li>Itérer en fonction des résultats pour apprendre encore plus efficacement.</li>
          </ol>
          <p><strong>Avantage :</strong> Vous combinez la théorie et la pratique, ce qui accélère la maîtrise et maximise les résultats pour votre business.</p>

          <h3>Étapes de l'Apprentissage Actif</h3>
          
          <h4>1. Identifier un Domaine Clé à Maîtriser</h4>
          <p><strong>Pourquoi :</strong> Se concentrer sur un domaine précis permet d'obtenir des résultats rapides et mesurables.</p>
          <p><strong>Comment :</strong></p>
          <ul>
            <li>Listez les compétences nécessaires pour votre activité d'infopreneur (ex. : copywriting, montage vidéo, automatisation, storytelling).</li>
            <li>Priorisez celles qui auront le plus grand impact immédiat sur votre business.</li>
          </ul>
          <p><strong>Exemple :</strong></p>
          <ul>
            <li>Si vous voulez améliorer vos ventes en ligne, commencez par le copywriting persuasif.</li>
          </ul>

          <h4>2. Fixer un Objectif Précis</h4>
          <p><strong>Pourquoi :</strong> Un objectif clair vous aide à mesurer vos progrès.</p>
          <p><strong>Comment :</strong></p>
          <ul>
            <li>Définissez un objectif SMART (Spécifique, Mesurable, Atteignable, Réaliste, Temporel).</li>
            <li>Exemple : "Créer une landing page avec un taux de conversion de 20 % en appliquant les principes de copywriting dans les 2 semaines."</li>
          </ul>

          <h4>3. Apprendre en Petites Étapes</h4>
          <p><strong>Pourquoi :</strong> Assimiler des concepts complexes par morceaux facilite leur mise en œuvre.</p>
          <p><strong>Comment :</strong></p>
          <ul>
            <li>Suivez des formations courtes (webinaires, tutoriels, e-books).</li>
            <li>Notez les 3 à 5 points d'action principaux à retenir après chaque session.</li>
          </ul>
          <p><strong>Outils :</strong></p>
          <ul>
            <li>YouTube : Tutoriels pratiques pour des compétences spécifiques.</li>
            <li>Plateformes de formation : Udemy, Coursera, ou MasterClass.</li>
            <li>Livres rapides à lire : Ex. : "Made to Stick" pour le storytelling.</li>
          </ul>

          <h4>4. Appliquer Immédiatement</h4>
          <p><strong>Pourquoi :</strong> La pratique consolide la théorie et aide à retenir plus durablement.</p>
          <p><strong>Comment :</strong></p>
          <ul>
            <li>Appliquez ce que vous apprenez directement sur vos projets :
              <ul>
                <li>Lancez un test A/B en marketing.</li>
                <li>Tournez une première vidéo avec les techniques de montage apprises.</li>
                <li>Créez un e-book en suivant des principes de design simplifiés.</li>
              </ul>
            </li>
            <li>Utilisez des feedbacks pour ajuster.</li>
          </ul>
          <p><strong>Astuce :</strong><br />
          Réalisez un projet concret à chaque étape de l'apprentissage. Par exemple, si vous apprenez à automatiser une tâche, 
          mettez immédiatement en place une séquence d'e-mails avec un outil comme Mailchimp.</p>

          <h4>5. Collaborer pour Apprendre</h4>
          <p><strong>Pourquoi :</strong> Le networking accélère l'apprentissage en confrontant vos idées à celles des autres.</p>
          <p><strong>Comment :</strong></p>
          <ul>
            <li>Rejoignez des groupes d'infopreneurs ou des masterminds.</li>
            <li>Participez à des discussions sur LinkedIn ou des forums spécifiques.</li>
            <li>Collaborez sur un projet pilote avec un pair pour partager les compétences.</li>
          </ul>

          <h4>6. Obtenir des Retours</h4>
          <p><strong>Pourquoi :</strong> Les retours permettent d'ajuster et de perfectionner vos compétences.</p>
          <p><strong>Comment :</strong></p>
          <ul>
            <li>Demandez des feedbacks à vos clients ou à votre audience (par exemple, via des sondages).</li>
            <li>Analysez les données objectives (taux de clics, ventes, engagement).</li>
            <li>Recherchez des critiques constructives auprès de mentors ou de pairs.</li>
          </ul>

          <h4>7. Enseigner ce que Vous Apprenez</h4>
          <p><strong>Pourquoi :</strong> Enseigner consolide vos connaissances et renforce votre autorité.</p>
          <p><strong>Comment :</strong></p>
          <ul>
            <li>Créez un tutoriel vidéo ou un guide sur le sujet que vous venez de maîtriser.</li>
            <li>Partagez vos expériences dans un webinaire ou sur les réseaux sociaux.</li>
            <li>Réalisez un contenu basé sur vos essais et erreurs (ex. : "Ce que j'ai appris en automatisant mes tâches marketing").</li>
          </ul>

          <h3>Outils et Stratégies pour l'Apprentissage Actif</h3>
          <h4>1. Gestion de Connaissances</h4>
          <ul>
            <li>Notion : Centralisez vos notes, ressources et idées.</li>
            <li>Roam Research : Créez des connexions entre vos apprentissages pour structurer vos pensées.</li>
          </ul>

          <h4>2. Formation Interactive</h4>
          <ul>
            <li>Coursera ou LinkedIn Learning : Formations pratiques avec des exercices intégrés.</li>
            <li>Skillshare : Axé sur des projets concrets à réaliser.</li>
          </ul>

          <h4>3. Outils de Mise en Pratique</h4>
          <ul>
            <li>Canva : Design simplifié pour vos contenus visuels.</li>
            <li>Zapier : Automatisation immédiate pour expérimenter avec vos flux de travail.</li>
            <li>ChatGPT : Assistance pour rédiger ou brainstormer.</li>
          </ul>

          <h3>Exemple d'Apprentissage Actif</h3>
          <p><strong>Objectif : Améliorer mon Copywriting pour Booster mes Conversions</strong></p>
          <ol>
            <li>Identifier : Besoin d'écrire des e-mails persuasifs.</li>
            <li>Apprendre :
              <ul>
                <li>Formation : "Les bases du copywriting" sur Udemy.</li>
                <li>Livre : "The Copywriter's Handbook" de Robert Bly.</li>
              </ul>
            </li>
            <li>Appliquer :
              <ul>
                <li>Rédiger 3 nouveaux e-mails pour une campagne en utilisant les principes appris.</li>
                <li>Tester différentes approches (ex. : appels à l'action forts vs. subtiles).</li>
              </ul>
            </li>
            <li>Feedback :
              <ul>
                <li>Analyser les taux d'ouverture et de clics.</li>
                <li>Ajuster en fonction des résultats.</li>
              </ul>
            </li>
            <li>Partager :
              <ul>
                <li>Écrire un post LinkedIn : "Comment j'ai doublé mon taux de clic grâce à 3 astuces de copywriting."</li>
              </ul>
            </li>
          </ol>

          <h3>Avantages pour un Infopreneur</h3>
          <ol>
            <li>Adaptabilité : Vous apprenez exactement ce dont vous avez besoin, sans gaspiller de temps sur des sujets non pertinents.</li>
            <li>Crédibilité renforcée : L'application rapide vous permet de partager des résultats concrets avec votre audience.</li>
            <li>Progrès mesurables : Chaque nouvelle compétence est directement liée à la croissance de votre activité.</li>
          </ol>
        </div>
      </div>
    </div>
  );
}