import React, { useState } from 'react';
import { Check, ArrowRight } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { saveLeadToFirebase } from '../services/firebase';
import ReservationModal from './ReservationModal';
import AuthModal from './AuthModal';
import PasswordConfirmModal from './PasswordConfirmModal';
import EmailVerificationModal from './EmailVerificationModal';
import CryptoLogos from './CryptoLogos';

const features = [
  "Accès à toutes les formations",
  "Rentabilité de gains et de temps",
  "Automatisation et productivité (de qualité)",
  "Soirée Networking",
];

const initialFormData = {
  prenom: '',
  email: '',
  password: ''
};

export default function Pricing() {
  const [showReservation, setShowReservation] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  const resetForm = () => {
    setFormData(initialFormData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.email.includes('@')) {
      toast.error('Email invalide');
      return;
    }

    if (formData.prenom.length < 2) {
      toast.error('Le prénom doit contenir au moins 2 caractères');
      return;
    }

    if (formData.password.length < 6) {
      toast.error('Le mot de passe doit contenir au moins 6 caractères');
      return;
    }

    setShowPasswordConfirm(true);
  };

  const handleRegistrationSuccess = () => {
    setShowPasswordConfirm(false);
    setShowEmailVerification(true);
    resetForm();
  };

  return (
    <section id="reservation" className="py-24 bg-gradient-to-br from-purple-900 via-[#111827] to-black relative overflow-hidden">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zNiAxOGMtNi42MjcgMC0xMiA1LjM3My0xMiAxMnM1LjM3MyAxMiAxMiAxMiAxMi01LjM3MyAxMi0xMi01LjM3My0xMi0xMi0xMnptMCAxOGMtMy4zMTQgMC02LTIuNjg2LTYtNnMyLjY4Ni02IDYtNiA2IDIuNjg2IDYgNi0yLjY4NiA2LTYgNnoiIGZpbGw9IiNmZWRlNTgiIGZpbGwtb3BhY2l0eT0iMC4yIi8+PC9nPjwvc3ZnPg==')] opacity-10"></div>
      
      <div className="container mx-auto px-4">
        <h2 className="text-6xl font-bold text-center text-[#fede58] mb-16">Je Réserve Ma Place</h2>
        
        <div className="max-w-6xl mx-auto flex flex-col lg:flex-row items-stretch gap-12">
          {/* Section Prix et Description */}
          <div className="flex-1">
            <CryptoLogos />
            <div className="text-[#fede58] text-2xl mb-8 text-center">Offre unique</div>
            
            <p className="text-xl text-gray-300 mb-8">
              Rejoignez une communauté exclusive d'infopreneur et transformez votre expertise en succès digital.
            </p>
            
            <div className="space-y-4 mb-8">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-[#fede58]/20 flex items-center justify-center">
                    <Check className="w-4 h-4 text-[#fede58]" />
                  </div>
                  <span className="text-gray-300">{feature}</span>
                </div>
              ))}
            </div>

            <div className="text-red-400 font-medium mb-8 animate-pulse text-center">
              Bonus limités aux 25 premiers
            </div>
          </div>

          {/* Formulaire d'inscription */}
          <div className="lg:w-[450px] w-full">
            <div className="bg-white/15 backdrop-blur-lg p-8 rounded-3xl border border-white/30 shadow-2xl">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="prenom" className="block text-[#fede58] text-lg font-medium mb-2">
                    Prénom
                  </label>
                  <input
                    type="text"
                    id="prenom"
                    value={formData.prenom}
                    onChange={(e) => setFormData(prev => ({ ...prev, prenom: e.target.value }))}
                    className="w-full bg-white/20 border-2 border-white/30 rounded-lg px-4 py-3 text-white placeholder-white/50 focus:ring-2 focus:ring-[#fede58] focus:border-transparent transition-all duration-300 text-lg font-medium shadow-inner"
                    placeholder="Votre prénom"
                    required
                    minLength={2}
                  />
                </div>
                
                <div>
                  <label htmlFor="email" className="block text-[#fede58] text-lg font-medium mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    className="w-full bg-white/20 border-2 border-white/30 rounded-lg px-4 py-3 text-white placeholder-white/50 focus:ring-2 focus:ring-[#fede58] focus:border-transparent transition-all duration-300 text-lg font-medium shadow-inner"
                    placeholder="votre@email.com"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="password" className="block text-[#fede58] text-lg font-medium mb-2">
                    Mot de passe
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={formData.password}
                    onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                    className="w-full bg-white/20 border-2 border-white/30 rounded-lg px-4 py-3 text-white placeholder-white/50 focus:ring-2 focus:ring-[#fede58] focus:border-transparent transition-all duration-300 text-lg font-medium shadow-inner"
                    placeholder="••••••••"
                    required
                    minLength={6}
                  />
                </div>

                <button 
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-gradient-to-r from-[#fede58] to-yellow-400 text-black font-bold py-4 px-8 rounded-xl text-xl relative overflow-hidden group transition-all duration-300 hover:scale-105 shadow-xl hover:shadow-2xl mt-8 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="relative z-10 flex items-center justify-center gap-2">
                    {isSubmitting ? 'Inscription en cours...' : (
                      <>
                        Join Now
                        <ArrowRight className="w-5 h-5 animate-pulse" />
                      </>
                    )}
                  </span>
                  <div className="absolute inset-0 bg-white/20 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left"></div>
                </button>

                <div className="text-center mt-4">
                  <button
                    type="button"
                    onClick={() => setShowAuth(true)}
                    className="text-gray-300 hover:text-white transition-colors text-sm"
                  >
                    Avez-vous déjà un compte ? Se connecter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <AuthModal 
        isOpen={showAuth}
        onClose={() => setShowAuth(false)}
      />

      <PasswordConfirmModal 
        isOpen={showPasswordConfirm}
        onClose={() => setShowPasswordConfirm(false)}
        formData={formData}
        onSuccess={handleRegistrationSuccess}
      />

      <EmailVerificationModal
        isOpen={showEmailVerification}
        onClose={() => {
          setShowEmailVerification(false);
          resetForm();
        }}
        email={formData.email}
      />

      <ReservationModal 
        isOpen={showReservation}
        onClose={() => setShowReservation(false)}
        leadData={formData}
      />
    </section>
  );
}